import { ErrorBoundary } from '@pages/error/ErrorBoudary';
import { RootLayout } from '@router/root/RootLayout';
import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { AdminLayout } from './root/AdminLayout';

const AboutPage = lazy(() => {
  return import('../pages/about/AboutPage');
});
const HomePage = lazy(() => {
  return import('../pages/home/HomePage');
});
const ServicesPage = lazy(() => {
  return import('../pages/services/ServicesPage/ServicesPage');
});

const WeddingsPage = lazy(() => {
  return import('../pages/services/WeddingsPage/WeddingsPage');
});

const EventsPage = lazy(() => {
  return import('../pages/services/EventsPage/EventsPage');
});
const CelebrationsPage = lazy(() => {
  return import('../pages/services/CelebrationsPage/CelebrationsPage');
});

const PromPage = lazy(() => {
  return import('../pages/services/PromPage/PromPage');
});
const SchoolGraduationBall = lazy(() => {
  return import('../pages/services/SchoolGraduationBall/SchoolGraduationBall');
});
const ExtrasPage = lazy(() => {
  return import('../pages/extras/ExtrasPage/ExtrasPage');
});

const MirrorPhotoBoothPage = lazy(() => {
  return import('../pages/extras/MirrorPhotoBooth/MirrorPhotoBoothPage');
});

const SmokeBombsPage = lazy(() => {
  return import('../pages/extras/SmokeBombs/SmokeBombsPage');
});

const LowFogPage = lazy(() => {
  return import('../pages/extras/LowFogPage/LowFogPage');
});

const WeddingSparksPage = lazy(() => {
  return import('../pages/extras/SparksPage/SparksPage');
});

const NapisLovePage = lazy(() => {
  return import('../pages/extras/NapisLovePage/NapisLovePage');
});

const NapisMiloscPage = lazy(() => {
  return import('../pages/extras/NapisMilosc/NapisMilosc');
});

const NeonLightsPage = lazy(() => {
  return import('../pages/extras/NeonLightsPage/NeonLightsPage');
});

const LightDecorPage = lazy(() => {
  return import('../pages/extras/LightDecorPage/LightDecorPage');
});

const HotCasinoPage = lazy(() => {
  return import('../pages/extras/HotCasino/HotCasino');
});
const ReviewsPage = lazy(() => {
  return import('../pages/Reviews/ReviewsPage');
});
const ContactPage = lazy(() => {
  return import('../pages/contact/ContactPage');
});
const DjsPage = lazy(() => {
  return import('../pages/landing-pages/Djs/DjsPage');
});
const McPage = lazy(() => {
  return import('../pages/landing-pages/McPage/McPage');
});
const InstrumentalistPage = lazy(() => {
  return import('../pages/landing-pages/Instrumentalist/InstrumentalistPage');
});
const AdminPage = lazy(() => {
  return import('../pages/admin/Admin');
});
const LoginPage = lazy(() => {
  return import('../pages/auth/login/LoginPage');
});
const SigninPage = lazy(() => {
  return import('../pages/auth/signin/SigninPage');
});
const PrivatePolicy = lazy(() => {
  return import('../pages/policy/PolicyPrivate');
});
const RangeOfActivity = lazy(() => {
  return import('../pages/range-of-activity/RangeOfActivity');
});

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      { path: '/', element: <HomePage /> },
      { path: 'o-nas', element: <AboutPage /> },
      {
        path: 'uslugi',
        element: <ServicesPage />,
      },
      {
        path: 'uslugi/dj-na-wesele/:city',
        element: <WeddingsPage />,
      },
      {
        path: 'dj-na-impreze/:city',
        element: <DjsPage />,
      },
      {
        path: 'wodzirej/:city',
        element: <McPage />,
      },
      {
        path: 'uslugi/akordeonista-saksofon/:city',
        element: <InstrumentalistPage />,
      },
      {
        path: 'uslugi/agencja-eventowa/:city',
        element: <EventsPage />,
      },
      {
        path: 'uslugi/dj-na-impreze/:city',
        element: <CelebrationsPage />,
      },
      {
        path: 'uslugi/dj-na-studniowke/:city',
        element: <PromPage />,
      },
      {
        path: 'uslugi/dj-na-bal-8-klas/:city',
        element: <SchoolGraduationBall />,
      },
      {
        path: 'atrakcje',
        element: <ExtrasPage />,
      },
      {
        path: 'atrakcje/fotobudka/:city',
        element: <MirrorPhotoBoothPage />,
      },
      {
        path: '/atrakcje/kolorowy-dym-na-wesele/:city',
        element: <SmokeBombsPage />,
      },
      {
        path: 'atrakcje/ciezki-dym/:city',
        element: <LowFogPage />,
      },
      {
        path: 'atrakcje/fontanny-iskier/:city',
        element: <WeddingSparksPage />,
      },
      {
        path: 'atrakcje/napis-love/:city',
        element: <NapisLovePage />,
      },
      {
        path: 'atrakcje/napis-milosc/:city',
        element: <NapisMiloscPage />,
      },
      {
        path: 'atrakcje/neonowy-napis-na-wesele/:city',
        element: <NeonLightsPage />,
      },
      {
        path: 'atrakcje/dekoracja-swiatlem/:city',
        element: <LightDecorPage />,
      },
      {
        path: 'atrakcje/kasyno/:city',
        element: <HotCasinoPage />,
      },
      {
        path: 'reviews',
        element: <ReviewsPage />,
      },
      {
        path: 'kontakt',
        element: <ContactPage />,
      },
      {
        path: 'polityka-prywatnosci',
        element: <PrivatePolicy />,
      },
      {
        path: 'zasieg-dzialania',
        element: <RangeOfActivity />,
      },
    ],
  },
  {
    path: '/admin',
    errorElement: <ErrorBoundary />,
    element: <AdminLayout />,
    children: [
      { path: '', element: <AdminPage /> },
      { path: 'auth/login', element: <LoginPage /> },
      { path: 'auth/signup', element: <SigninPage /> },
    ],
  },
]);
