import { useAppDispatch } from '@store/hooks';
import { snackbarAction } from '@store/slices/snackbarReducer';
import { useEffect, useState } from 'react';
import { AreaProps, CityItemProps } from 'src/types/event-area-types';

export const useGetArea = () => {
  const [area, setArea] = useState<AreaProps[]>([]); // Domyślnie ustaw pustą tablicę
  const [selectedCities, setSelectedCities] = useState<CityItemProps[]>([]); // Wybrane miasta
  const [allCities, setAllCities] = useState<CityItemProps[]>([]); // Wszystkie miasta
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/eventArea.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        // Upewnij się, że dane są tablicą
        const areas = Array.isArray(data) ? data : [];
        setArea(areas);

        // Przetwarzanie danych miast
        const allCitiesList: CityItemProps[] = areas.flatMap((area) => {
          return area.citis;
        });
        const selectedCitiesList: CityItemProps[] = allCitiesList.filter(
          (city) => {
            return city.selectedArea;
          }
        );

        setAllCities(allCitiesList);
        setSelectedCities(selectedCitiesList);
      } catch (error) {
        dispatch(
          snackbarAction({
            message: `Problem z pobraniem miejscowości. Error: ${error}`,
            status: 'error',
          })
        );
      }
    };

    fetchData();
  }, []);

  return { area, selectedCities, allCities };
};
