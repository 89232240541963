/* eslint-disable prettier/prettier */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  ISchemaOrgReview,
  SchemaAgregateRating,
  SchemaAreaServed,
  SchemaProps,
} from 'src/types/schemaOrg';

import { SliceName } from '../enums';

const initialState: SchemaProps = {
  schmaOrg: {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    name: 'Event Rulers - Więcej niż Wodzireje!',
    areaServed: [],
    image: 'https://eventrulers.pl/img/Hero.jpeg',
    description:
      'Organizacja niezapomnianych wydarzeń w Twojej okolicy. Skorzystaj z profesjonalnej agencji eventowej! Twój event może być niepowtarzalny i pełen energii. Zadbaj o najlepszą oprawę muzyczną z topową ekipą w branży',
    telephone: '+48 698-212-279',
    email: 'wedding@eventrulers.pl',
    url: 'https://eventrulers.pl',
    priceRange: '$$$',
    openingHours: 'Mo-Fr 08:00-18:00',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'ul. Marcina Kasprzaka 15',
      addressLocality: 'Olsztyn',
      postalCode: '10-057',
      addressRegion: 'Warmińsko-Mazurskie',
      addressCountry: 'PL',
    },
    sameAs: [
      'https://www.facebook.com/eventrulers',
      'https://www.instagram.com/eventrulers',
      'https://www.linkedin.com/company/rulers-group',
    ],
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.7',
      bestRating: '5',
      reviewCount: '119',
    },
    potentialAction: {
      '@type': 'CommunicateAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: 'tel:+48698212279',
      },
      name: 'Zadzwoń do nas',
    },
    review: [],
  },
  preloadImg: 'https://eventrulers.pl/img/Hero.jpeg',
  cannonicalPage: 'https://eventrulers.pl',
  alternate: null,
};

const schemaSlice = createSlice({
  name: SliceName.SCHEMA,
  initialState,
  reducers: {
    aggregateRatingFunc: (
      state,
      action: PayloadAction<Omit<SchemaAgregateRating, '@type'>>
    ) => {
      state.schmaOrg.aggregateRating.ratingValue =
        action.payload.ratingValue ||
        state.schmaOrg.aggregateRating.ratingValue;
      state.schmaOrg.aggregateRating.reviewCount =
        action.payload.reviewCount ||
        state.schmaOrg.aggregateRating.reviewCount;
    },
    updateHeadFunc: (
      state,
      action: PayloadAction<{
        description: string;
        image: string;
        areaServed?: SchemaAreaServed[];
        title: string;
        preloadImg: string;
        cannonicalPage?: string;
        alternate?: string | null;
      }>
    ) => {
      state.schmaOrg.name = action.payload.title;
      state.schmaOrg.description = action.payload.description;
      state.schmaOrg.image = action.payload.image;
      state.schmaOrg.areaServed = action.payload.areaServed || [];
      state.preloadImg = action.payload.preloadImg;
      state.cannonicalPage =
        action.payload.cannonicalPage ?? state.cannonicalPage;
      state.alternate = action.payload.alternate ?? state.alternate;
    },
    updateReviewsFunc: (state, action: PayloadAction<ISchemaOrgReview[]>) => {
      state.schmaOrg.review = action.payload;
    },
  },
});

export const { aggregateRatingFunc, updateHeadFunc, updateReviewsFunc } =
  schemaSlice.actions;

export const SchemaActions = schemaSlice.actions;

export default schemaSlice.reducer;
