import { fontSizeMap } from '@components/UI';
import { CustomColors, FontFamilies, SizesType } from '@components/UI/types';

import { useAppSelector } from '@store/hooks';
import clsx from 'clsx';
import { FC, memo } from 'react';
import styled from 'styled-components';

import { NavigationPathProps } from '..';

import classes from '../style.module.scss';

import NavLink from './NavLink';

interface StyledAnchorProps {
  color?: CustomColors;
  uppercase?: boolean;
  size?: SizesType;
  fontFamily?: FontFamilies;
  hoverColor?: CustomColors;
}

export const StyledAnchor = styled.a<StyledAnchorProps>`
  color: ${({ color }) => {
    return color ? `var(--${color})` : 'inherit';
  }};
  text-transform: ${({ uppercase }) => {
    return uppercase ? 'uppercase' : 'none';
  }};
  font-size: ${({ size }) => {
    return fontSizeMap[size as SizesType] || '1rem';
  }};
  font-family: ${({ fontFamily }) => {
    return fontFamily || 'inherit';
  }};
  padding: 1.2rem 1rem;
  text-decoration: none;

  &:hover {
    color: ${({ hoverColor }) => {
      return hoverColor && `var(--${hoverColor})`;
    }};
  }

  &.active {
    color: var(--main-gold);
  }
`;

interface DropdownProps {
  subMenu: NavigationPathProps['navigation'];
  parentPath: string;
  color?: CustomColors;
  hoverColor?: CustomColors;
}

export const DropdownMenu: FC<DropdownProps> = ({
  subMenu,
  parentPath,
  color = '--background-color-soft',
  hoverColor,
}) => {
  const isAdmin = useAppSelector((state) => {
    return state.user.user_type === 'admin';
  });

  const menuItems = subMenu.map((item) => {
    const isExternal = item.path.startsWith('https://');
    const itemPath = isAdmin ? item.path.slice(0, -8) : item.path;

    return (
      <li
        key={item.path}
        className={clsx(classes['dropdown-menu-item'])}
        style={{ listStyle: 'none' }}
      >
        {isExternal ? (
          <StyledAnchor
            href={item.path}
            target="_blank"
            rel="noopener noreferrer"
            color={color}
            hoverColor={hoverColor}
            className={clsx(classes.link, classes['dropdown-menu'])}
            size="xx-small"
            style={{ textTransform: 'uppercase' }}
          >
            {item.name}
          </StyledAnchor>
        ) : (
          <NavLink
            to={`${parentPath}${itemPath}`}
            color={color}
            className={clsx(classes.link, classes['dropdown-menu'])}
            size="xx-small"
          >
            {item.name}
          </NavLink>
        )}
      </li>
    );
  });

  return (
    <div className={classes.wrapper}>
      <div className={classes.space} />
      <ul className={classes.submenu}>{menuItems}</ul>
    </div>
  );
};

export default memo(DropdownMenu);
