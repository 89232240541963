export function getPromYear(currentDate: Date, mounth: number): number {
  const currentYear = currentDate.getFullYear();
  const cutoffDate = new Date(currentYear, mounth, 1);
  return currentDate < cutoffDate ? currentYear : currentYear + 1;
}

export const convertUnixToDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000); // Konwertujemy z sekund na milisekundy
  return date.toISOString().split('T')[0]; // Zwracamy tylko część daty
};
