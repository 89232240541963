import { DecoratorVariant, LazyImageProps } from '@components/UI';
import { useGetArea } from '@hooks/useGetArea';
import { BoxProps } from '@mui/material/Box/Box';
import { useAppDispatch } from '@store/hooks';
import { updateHeadFunc } from '@store/slices/schemaReducer';
import React, { FC, ReactNode, memo } from 'react';

import { useLocation } from 'react-router-dom';
import { AreaProps, CityProps } from 'src/types/event-area-types';
import { SchemaAreaServed, SchemaOrgIterface } from 'src/types/schemaOrg';

import { PageContent } from '../page-content/PageContent';
import { PageImage } from '../page-image/PageImage';

import classes from './style.module.scss';

interface PageLayoutProps {
  src: string;
  objectPosition?: LazyImageProps['objectPosition'];
  title?: string;
  htmlTitle: string;
  subTitle?: string;
  children?: ReactNode;
  id: string;
  maxWidth?: BoxProps['maxWidth'];
  className?: BoxProps['className'];
  decorator?: DecoratorVariant;
  filterCity?: CityProps;
  description: SchemaOrgIterface['description'];
  image?: string;
  cannonicalPage?: string;
  currentCity?: string;
}

export const PageLayout: FC<PageLayoutProps> = ({
  src,
  title,
  htmlTitle,
  objectPosition,
  subTitle,
  children,
  id,
  maxWidth = '100%',
  className,
  decorator,
  filterCity,
  description,
  image,
  cannonicalPage,
}) => {
  const dispatch = useAppDispatch();
  const { area: eventArea } = useGetArea();

  const cityName = filterCity?.name.nominativus
    ? filterCity.name.nominativus
    : 'Olsztyn';

  const location = useLocation();
  const { pathname } = location;

  let url: URL | null = null;
  let path: string = '';
  let domena: string | null = null;

  try {
    if (cannonicalPage) {
      // Jeśli `cannonicalPage` jest ustawiony, próbujemy zbudować URL
      url = new URL(cannonicalPage, window.location.origin); // Dodanie fallbacku na `window.location.origin`
      path = url.pathname;
      domena = url.origin; // Pobieramy domenę
    } else {
      // Jeśli `cannonicalPage` nie istnieje, korzystamy z `pathname`
      console.warn('cannonicalPage is missing, falling back to pathname.');
      path = pathname;
      domena = window.location.origin; // Bazujemy na aktualnej domenie
    }
  } catch (error) {
    console.error('Error constructing URL:', error);
    path = pathname; // Fallback na `pathname`
    domena = window.location.origin;
  }

  // Alternatywne URL - działa dla obu typów stron
  const alternate = path !== pathname ? `${domena}${pathname}` : null;

  const findRegionByCity = (cityName: string, eventAreas: AreaProps[] = []) => {
    if (!eventAreas.length) {
      console.warn('No event areas provided.');
      return null;
    }

    for (const region of eventAreas) {
      const city = region.citis.find((city) => {
        return (
          city.name.nominativus.toLocaleLowerCase() === cityName.toLowerCase()
        );
      });

      if (city) {
        return region;
      }
    }

    return null;
  };

  const currentArea = findRegionByCity(cityName, eventArea);

  const areaServed =
    currentArea?.citis?.map((city) => {
      const { addressLocality, postalCode } = city;
      return {
        '@type': 'Place',
        name: city.name.nominativus,
        address: {
          '@type': 'PostalAddress',
          addressLocality,
          postalCode,
          addressRegion: currentArea?.name,
          addressCountry: {
            '@type': 'Country',
            name: 'PL',
          },
        },
      } as SchemaAreaServed;
    }) || [];

  dispatch(
    updateHeadFunc({
      description,
      title: htmlTitle,
      image: image ? image : src,
      preloadImg: src,
      cannonicalPage,
      areaServed,
      alternate,
    })
  );

  return (
    <div key={location.pathname} className={classes.container} id={id}>
      <PageImage src={src} pageTitle={title} objectPosition={objectPosition} />
      <PageContent
        subTitle={subTitle}
        maxWidth={maxWidth}
        className={className}
        decorator={decorator}
      >
        {children}
      </PageContent>
    </div>
  );
};

export default memo(PageLayout);
