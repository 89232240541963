/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCityFilter, useMediaQuery } from '@hooks/index';
import { useMobile } from '@hooks/useMobile';
import { extrasRedirect } from '@pages/extras/extras-constants';
import React, { memo } from 'react';

import { useLocation } from 'react-router-dom';

import { CarouselHeader, DecoratorSVG, Divider, Typography } from '..';

import classes from './style.module.scss';

export const CarouselWithFooter = memo(() => {
  const isMobile = useMobile();
  const sm = useMediaQuery('sm');

  const path = useLocation();

  const { filterCity } = useCityFilter();

  const filterExtras = extrasRedirect
    .map((item) => {
      const updatedPath = `${
        item.path
      }/${filterCity?.addressLocality.toLocaleLowerCase()}`;
      return {
        ...item,
        path: updatedPath,
      };
    })
    .filter((updatedItem) => {
      return updatedItem.path !== path.pathname;
    });

  return (
    <div className={classes['carousel-with-footer']}>
      <div className={classes.ornament}>
        <DecoratorSVG
          variant="decorator_6"
          height={isMobile ? 20 : 50}
          color="--dark-gold"
          duration={2}
          delay={0.5}
          m={isMobile ? 2 : 4}
        />
      </div>
      <div className={classes.heading}>
        <Typography
          component="h2"
          size={{
            xs: 'small',
            sm: 'medium',
            md: 'large',
            lg: 'x-large',
            xl: 'x-large',
          }}
          color="--dark-gold"
          fontFamily="barlow-italic"
          letterSpacing={1}
          textAlign="center"
          mb={4}
        >
          Nasze Dodatki - Twój Klucz do Niezapomnianego Wesela
        </Typography>
        <Divider />
      </div>
      <div className={classes['carousel-wrapper']}>
        <CarouselHeader
          loop
          className={classes.wrapper}
          showButton={!isMobile}
          items={filterExtras}
          slidesPerView={sm ? 1 : isMobile ? 1 : 2}
          cardProps={{ height: 400, titleTextAlign: 'center' }}
        />
      </div>
    </div>
  );
});
