import useLocalStorage from '@hooks/useLocalStorage';
import { ReviewProps, ReviewResponseProps } from '@pages/home/review/types';
import { router } from '@router/Router';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  aggregateRatingFunc,
  updateReviewsFunc,
} from '@store/slices/schemaReducer';
import { userAction } from '@store/slices/userReducer';
import { convertUnixToDate } from '@utils/date/date-utils';
import React, { Suspense, useEffect, useState } from 'react';

import './app.module.scss';

import { Helmet } from 'react-helmet';
import { RouterProvider } from 'react-router-dom';

import { Loader } from './components';

import { useFetchData } from './hooks';
import { ISchemaOrgReview } from './types/schemaOrg';
import { UserProps } from './types/user';

function App() {
  const dispatch = useAppDispatch();
  const url = 'https://rulersgroup.pl/server/api/reviews/rating';

  const reviewUrl = `${process.env.REACT_APP_SERVER_URL}/server/api/reviews`;
  const review = useFetchData(reviewUrl) as {
    data?: ReviewResponseProps;
    loading: boolean;
  };

  const { schmaOrg, cannonicalPage, alternate, preloadImg } = useAppSelector(
    (state) => {
      return state.schema;
    }
  );

  useEffect(() => {
    if (Array.isArray(review?.data)) {
      const reviews = review.data as unknown as ReviewProps[];

      const updatedReviews = reviews.map((review) => {
        return {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: review.author_name,
          },
          datePublished: convertUnixToDate(review.time),
          reviewBody: review.text,
          reviewRating: {
            '@type': 'Rating',
            bestRating: '5',
            ratingValue: `${review.rating}`,
          },
        } as ISchemaOrgReview;
      });

      dispatch(updateReviewsFunc(updatedReviews));
    }
  }, [review]);

  const [description, setDescription] = useState(schmaOrg.description);
  const [cannonicPage, setCannonicPage] = useState(cannonicalPage);

  const { data } = useFetchData(url) as unknown as {
    data: {
      rating: string;
      user_ratings_total: string;
    };
  };

  if (data) {
    dispatch(
      aggregateRatingFunc({
        ratingValue: data.rating,
        reviewCount: data.user_ratings_total,
      })
    );
  }

  const [user] = useLocalStorage<Omit<
    UserProps & { expiration: string },
    'user_password'
  > | null>('user', null);

  useEffect(() => {
    if (user?.user_token && new Date(user.expiration) > new Date()) {
      dispatch(userAction(user));
    }
  }, [user]);

  useEffect(() => {
    if (schmaOrg.description) {
      setDescription(schmaOrg.description);
    }
    if (cannonicalPage) {
      setCannonicPage(cannonicalPage);
    }
  }, [schmaOrg, cannonicalPage]);

  return (
    <>
      <div key={schmaOrg.name}>
        <Helmet>
          <title>{schmaOrg.name}</title>
          <link rel="preload" as="image" href={preloadImg} />
          <link
            rel="canonical"
            href={cannonicPage ? cannonicPage : 'https://eventrulers.pl'}
            hrefLang="pl-pl"
          />
          {alternate && (
            <link rel="alternate" href={alternate} hrefLang="pl-pl" />
          )}
          <meta property="og:title" content={schmaOrg.name} />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <script type="application/ld+json">{JSON.stringify(schmaOrg)}</script>
        </Helmet>
      </div>
      <Suspense fallback={<Loader />}>
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
}

export default App;
