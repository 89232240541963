import { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { CityItemProps } from 'src/types/event-area-types';

import { useGetArea } from './useGetArea';

export const useCityFilter = () => {
  const { area: eventArea } = useGetArea(); // Korzystamy bezpośrednio z danych zwracanych przez useGetArea
  const [filterCity, setFilterCity] = useState<CityItemProps>({
    name: {
      nominativus: 'Olsztyn',
      locativus: 'Olsztynie',
      genetivus: 'Olsztyna',
    },
    addressLocality: 'olsztyn',
    postalCode: '10-057',
    selectedArea: true,
  });

  const location = useLocation();
  const pathname = location.pathname;
  const segments = pathname.split('/').filter(Boolean);
  const cityName = segments[segments.length - 1]; // Ostatni segment to nazwa miasta

  // Znajdź obszar zawierający miasto
  const placeIncludeCity = useMemo(() => {
    return eventArea.find((area) => {
      return area.citis.some((city) => {
        return city.addressLocality === cityName;
      });
    });
  }, [eventArea, cityName]);

  // Znajdź konkretne miasto
  const cityObject = useMemo(() => {
    return (
      placeIncludeCity?.citis.find((city) => {
        return city.addressLocality === cityName;
      }) || null
    );
  }, [placeIncludeCity, cityName]);

  // Zaktualizuj filtr miasta
  useEffect(() => {
    if (cityObject) {
      setFilterCity(cityObject);
    }
  }, [cityObject]);

  return { filterCity, eventArea };
};

export default useCityFilter;
